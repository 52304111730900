import React from "react";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import "./Menus.css";
import { useGetMenuListQuery } from "../../../components/products/productSlice";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Menus() {
  const isLogin = window.localStorage.getItem("isLogin");
  // const { data, isSuccess } = useGetMenuListQuery()

  const [data, setdata] = useState(null)

  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/category/filter`)
    setdata(res.data)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div
        className="collapse navbar-collapse mean-menu"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav m-0 p-0">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Feeding
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            {/*  <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul> */}
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Diapers
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Bath & Baby care
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Toys
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Travel
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Moms
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Memories
              <span className="fs-5"><b><FiChevronDown /></b></span>
            </Link>
            <ul className={`dropdown-menu dropAgro `}>
              <div className="row">
                <div className="col-lg-3 width-100pr">

                  <div className="menuList" style={{ paddingTop: "0px" }}>
                    <ul>

                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Baby Bottles
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Breastfeeding Access
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Bibs & Cloths
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight /> Teethers & Pacifiers
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to={`/products`} className="nav-link sub_menu_Text">
                          <FiChevronRight />  Feeding Essentials
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>

              </div>

            </ul>
          </li>
          {isLogin === true && (<li className="nav-item">
            <Link to="/" className="nav-link">
              Services
            </Link>
          </li>)}
        </ul>
      </div>
    </>
  );
}

export default Menus;
