import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetProductsQuery } from '../../products/productSlice';
import TodayDealsProduct from './today-deal-product/TodayDealsProduct'
import QuiekViewModal from '../../../pages/QueikViewModal/QuiekViewModal';
import { babySkinProducts } from '../../../common/allProductsData/allProductMockData';

function TodayDeals() {
  const { data, isLoading, error } = useGetProductsQuery();

  const todayProducts = data?.filter((currentItem) => {
    return currentItem.todays_deal === true;
  });

  const [modelDataId, setModelDataId] = useState(null)
  const setProduct_id = (id) => {
    setModelDataId(id)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id)
    setShow(true)
  };

  return (
    <>
      <section className="todayDealsSec p-30">
        <div className="container">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader d-flex justify-content-center align-items-end mb-4">
                <div className="fisherman-content">
                  <h3><strong>{babySkinProducts.title}</strong></h3>
                </div>

              </div>
            </div>
            {babySkinProducts?.productData?.map((item, i) => {
              return <div className="col-lg-3 col-md-6 col-sm-12" key={item.id}>
                <div className="featuredInfo">
                  <div className="featuredFigure">
                    <div className="featuredImg" style={{ textAlign: "center" }}>
                      <Link to={`/product/${item._id}`}>
                        <figure><img src={item?.proUrl} alt="Product" className="imgProduct" /></figure>
                      </Link>
                      <h6><b>{item.title}</b></h6>
                    </div>
                  </div>
                </div>
              </div>

            })}
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="fisherman-btn rounded-5">
            <Link to="/products" className="optional-btn">
              View More
            </Link>
          </div>
        </div>



        {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )}
      </section>}

    </>
  )
}

export default TodayDeals