import React from "react";
import { useGetCategoriesQuery } from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";

function ProductsCategory() {
  const { data, isLoading, error } = useGetCategoriesQuery();

  return (
    <>
      <section className="productsCategorySec">
        <div className="container">


          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content mb-3">
                <h3>Featured Categories</h3>
              </div>
            </div>

            {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <h4 style={{textAlign:"center" , color:"red"}}>Server Error</h4>}

            {data?.slice(0, 3).map((item,i) => {
              return <div key={item._id} className="col-lg-4 col-md-6 col-sm-12">
                <div className="productsCategoryItem">
                  <div className="categoryHeader">
                    <h5 className="title">{item.name}</h5>
                    <a href="/products">view all </a>
                  </div>
                  <div className="categoryBody">
                    <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[1].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                        <span className="sale">Sale</span>
                      </a>
                    </div>
                    <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[2].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div>
                    <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[3].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div>
                    <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[1].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;

