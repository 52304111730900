import React from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import "./SelectImageSlider.css";
import { ImgesData } from "../../proImgs/ImgesData";
import { productDetails } from "../../../../common/allProductsData/allProductMockData";

function SelectImageSlider({ handleUniqueID }) {
  return (
    <>

      <div className="selectImageInfo">
        <button type="button" className="slidePrev">
          <IoIosArrowUp />
        </button>
        <div className="selectImageSlider">

          {productDetails?.productDetailData?.map((item, i) => {
            return <div className="productSliderItem" key={i}>
              <img
                src={item.image.url}
                className="img-fluid"
                onClick={() => { handleUniqueID(item) }}
              />
            </div>
          })}
          {productDetails?.productDetailData?.map((item, i) => {
            return <div className="productSliderItem" key={i}>
              <img
                src={item.image.url}
                className="img-fluid"
                onClick={() => { handleUniqueID(item) }}
              />
            </div>
          })}
        </div>
        <button type="button" className="slideNext">
          <IoIosArrowDown />
        </button>
      </div>
    </>
  );
}

export default SelectImageSlider;
