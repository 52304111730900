import React from "react";
import { BsArrowRight } from "react-icons/bs";
import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";
import s1 from "../../../assets/img/serviceList/s1.webp"
import s2 from "../../../assets/img/serviceList/s2.webp"
import s3 from "../../../assets/img/serviceList/s3.webp"
import s4 from "../../../assets/img/serviceList/s4.webp"
import s5 from "../../../assets/img/serviceList/s5.webp"
import s6 from "../../../assets/img/serviceList/s6.webp"
import s7 from "../../../assets/img/serviceList/s7.webp"
import s8 from "../../../assets/img/serviceList/s8.webp"
import s9 from "../../../assets/img/serviceList/s9.webp"
import s10 from "../../../assets/img/serviceList/s10.webp"
import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList">
        <div className="container">
          <div className="serviceItemInfo">
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <img src={s1} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Diapres</b></h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <img src={s2} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Gear & Tarvel</b></h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <img src={s3} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Mother Tar</b></h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <img src={s4} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>
                  My Memory</b></h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <img src={s5} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Bottle Feeding</b></h5>
              </div>
            </div><div className="serviceItem">
              <div className="serviceItemIcon">
                <img src={s6} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Bibs & Pacifiers</b></h5>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
                <img src={s7} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Bath Times</b></h5>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
                <img src={s8} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Devices</b></h5>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
                <img src={s9} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Health & Fitness</b></h5>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
                <img src={s10} alt="s1" />
              </div>
              <div className="serviceItemText">
                <h5><b>Dishes & Food </b></h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
