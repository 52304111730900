import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { MdCall } from "react-icons/md";
import { HiMail } from "react-icons/hi";

import logo from "../../assets/img/logo.png"

import "./Footer.css";
import axios from "axios";
function Footer() {

  const [state, setState] = useState({
    about_company: "",
    office_address: "",
    location: "",
    phoneNo: "",
    email: "",
  })


  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/adminWeb_footer/get`)
    setState({
      about_company: res.data.about_company,
      office_address: res.data.office_address,
      location: res.data.contact_info.location,
      phoneNo: res.data.contact_info.phoneNo,
      email: res.data.contact_info.email,
    })
    window.localStorage.setItem('callNum', res.data.contact_info.phoneNo)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {/* Start Footer Section */}
      <section className="footer-section footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="">
                <h3>About Company</h3>
                <div className="row align-items-center">
                  <div className="col-lg-2 col-md-4 col-12">
                    <figure>
                      <img src={logo} alt="logo-footer" width={150} />
                    </figure>
                  </div>
                  <div className="col-lg-10 col-md-8 col-12">
                    <p><b>
                      Tinytycoons has now grown to become a much loved and trusted name for baby clothing, maternity essentials and children's fashion. We have good collection of baby dresses, Pure cotton baby clothes. With a carefully curated collection of designs in peppy colours and chic combinations that are elegantly stylish, our line of clothing has something for everyone's liking/ parents looking for practical, well-designed outfits for their children. If you need to contact us for getting our products
                    </b>
                    </p>
                  </div>

                </div>

              </div>
            </div>


            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Useful Links</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about"> Feeding</Link>
                  </li>

                  <li>
                    <Link to="/blog"> Daipers</Link>
                  </li>
                  <li>
                    <Link to="/photo-gallery"> Bath & Baby Care </Link>
                  </li>
                  <li>
                    <Link to="/faq">Toys</Link>
                  </li>
                  <li>
                    <Link to="/testimonial">Tarvel</Link>
                  </li>
                  <li>
                    <Link to="/view-all-brands">Moms</Link>
                  </li>
                  <li>
                    <Link to="/pickup_point_store">Memories</Link>
                  </li>

                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Address</h3>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    {/* <GoLocation /> */}
                    <span>
                      <h3>Location</h3>
                      <span>98B, II Floor, NFC, New Delhi, Delhi, INDIA,</span>
                    </span>
                  </div>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    {/* <MdCall /> */}
                    <span>
                      <h3>Call Us</h3>
                      <a href={`tel:${state?.phoneNo}`}> {state?.phoneNo}</a>
                    </span>
                  </div>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    {/* <HiMail /> */}
                    <span>
                      <h3>Email Us</h3>
                      <a href={`mailto:${state?.email}`}>
                        <span
                          className="__cf_email__"
                        >
                          {state?.email}
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Quicks Links</h3>
                </div>


                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="single-footer-widget">
                    <ul className="footer-quick-links sellerLists">
                      <li>
                        <Link to="/allSeller">Moms Blogs</Link>
                      </li>
                      <li>
                        <Link to="/seller/login">FAQs</Link>
                      </li>
                      <li>
                        <Link to="/seller/sign-Up">About Us</Link>
                      </li>
                      <li>
                        <Link to="/all_categories">AllCategories</Link>
                      </li>
                    </ul>
                  </div>
                </div>


              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3> Contact Us</h3>
                </div>

                <form className="newsletter-form">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button type="submit">
                    Subscribe Now
                    <i className="flaticon-plus"></i>
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>
                <ul className="footer-social">
                  <li>
                    <a href="https://mmslfashions.in/" className="facebook">
                      <FaFacebookF />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="twitter">
                      <BsTwitter />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="instagram">
                      <BsInstagram />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="whatsapp">
                      <BsWhatsapp />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="copyright-area-content">
              <div className="copyright-left">
                <p>
                  Copyright @
                  <script
                    data-cfasync="false"
                    src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>document.write(new Date().getFullYear())</script>{" "}
                  All Rights Reserved.
                </p>
              </div>

              <div className="copyright-right">
                <ul>
                  <li>
                    <Link to="/terms-of-use">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Section */}
    </>
  );
}

export default Footer;
