import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetFeaturedProductQuery } from '../../products/productSlice'
import QuiekViewModal from '../../../pages/QueikViewModal/QuiekViewModal'
import FeaturedProduct from './featured-product/FeaturedProduct'


function FeaturedProducts() {
  const { data, error, isLoading } = useGetFeaturedProductQuery()

  const [modelDataId, setModelDataId] = useState(null)
  const setProduct_id = (id) => {
    setModelDataId(id)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id)
    setShow(true)
  };

  return (
    <>
      <section className="productsSection  p-30">
        <div className="container">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader d-flex justify-content-center align-items-end mb-4">
                <div className="fisherman-content">
                  <h3><strong>Trendy Products</strong></h3>
                </div>

              </div>
            </div>
            <FeaturedProduct />
          </div>
        </div>

        {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )}
      </section>
    </>
  )
}

export default FeaturedProducts