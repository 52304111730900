// trendyProducts start -------------------------
import tp1 from "../../assets/prductsImg/tp1.webp"
import tp2 from "../../assets/prductsImg/tp2.webp"
import tp3 from "../../assets/prductsImg/tp3.webp"
import tp4 from "../../assets/prductsImg/tp4.jpg"
import tp5 from "../../assets/prductsImg/tp5.jpg"
import tp6 from "../../assets/prductsImg/tp6.webp"
import tp7 from "../../assets/prductsImg/tp7.jpg"
import tp8 from "../../assets/prductsImg/tp8.jpg"
// trendyProducts end -------------------------
// babyProducts start -------------------------

import bp1 from "../../assets/img/babyProducts/b1.jpg"
import bp2 from "../../assets/img/babyProducts/b2.jpg"
import bp3 from "../../assets/img/babyProducts/b3.jpg"
import bp4 from "../../assets/img/babyProducts/b4.jpg"
import bp5 from "../../assets/img/babyProducts/b5.jpg"
import bp6 from "../../assets/img/babyProducts/b6.jpg"
import bp7 from "../../assets/img/babyProducts/b7.jpg"
import bp8 from "../../assets/img/babyProducts/b8.jpg"
// babyProducts end -------------------------

// baby Skin Products start -------------------------

import bs1 from "../../assets/img/babySkinProducts/bs1.jpg"
import bs2 from "../../assets/img/babySkinProducts/bs2.jpg"
import bs3 from "../../assets/img/babySkinProducts/bs3.jpg"
import bs4 from "../../assets/img/babySkinProducts/bs4.jpg"
import bs5 from "../../assets/img/babySkinProducts/bs5.jpg"
import bs6 from "../../assets/img/babySkinProducts/bs6.jpg"
import bs7 from "../../assets/img/babySkinProducts/bs7.jpg"
import bs8 from "../../assets/img/babySkinProducts/bs8.jpg"
// baby Skin Products end -------------------------
// baby Bottles Products start -------------------------

import bbottles1 from "../../assets/img/babyBottles/bbottle.1.jpg"
import bbottles2 from "../../assets/img/babyBottles/bbottle.2.jpg"
import bbottles3 from "../../assets/img/babyBottles/bbottle.3.jpg"
import bbottles4 from "../../assets/img/babyBottles/bbottle.4.jpg"
import bbottles5 from "../../assets/img/babyBottles/bbottle.5.jpg"
import bbottles6 from "../../assets/img/babyBottles/bbottle.6.jpg"
import bbottles7 from "../../assets/img/babyBottles/bbottle.7.jpg"
import bbottles8 from "../../assets/img/babyBottles/bbottle.8.jpg"
import bbottles9 from "../../assets/img/babyBottles/bbottle.9.jpg"
import bbottles10 from "../../assets/img/babyBottles/bbottle.10.jpg"
import bbottles11 from "../../assets/img/babyBottles/bbottle.11.jpg"
import bbottles12 from "../../assets/img/babyBottles/bbottle.12.jpg"
import bbottles13 from "../../assets/img/babyBottles/bbottle.13.jpg"
import bbottles14 from "../../assets/img/babyBottles/bbottle.14.jpg"
import bbottles15 from "../../assets/img/babyBottles/bbottle.15.jpg"
import bbottles16 from "../../assets/img/babyBottles/bbottle.16.jpg"
import bbottles17 from "../../assets/img/babyBottles/bbottle.17.jpg"
import bbottles18 from "../../assets/img/babyBottles/bbottle.18.jpg"
import bbottles19 from "../../assets/img/babyBottles/bbottle.19.jpg"
import bbottles20 from "../../assets/img/babyBottles/bbottle.20.jpg"
import bbottles21 from "../../assets/img/babyBottles/bbottle.21.jpg"
import bbottles22 from "../../assets/img/babyBottles/bbottle.22.jpg"
import bbottles23 from "../../assets/img/babyBottles/bbottle.23.jpg"
import bbottles24 from "../../assets/img/babyBottles/bbottle.24.jpg"
import bbottles25 from "../../assets/img/babyBottles/bbottle.25.jpg"
import bbottles26 from "../../assets/img/babyBottles/bbottle.26.jpg"
import bbottles27 from "../../assets/img/babyBottles/bbottle.27.jpg"
// baby Bottles Products end -------------------------
export const trendyProducts = {
    title: "Trendy Products",
    productData: [
        { id: "2", proUrl: tp2, title: " Baby Wet Wipe Warmer and Dispenser" },
        { id: "3", proUrl: tp3, title: "Frida Soft Sink Baby Bath" },
        { id: "4", proUrl: tp4, title: " Baby Milk Feeding Bottle" },
        { id: "1", proUrl: tp1, title: "Himalaya Babycare Gift Basket" },
        { id: "5", proUrl: tp5, title: "Baby Girl Headband Infant Hair " },
        { id: "6", proUrl: tp6, title: "Imani Breast Pumb i2 Plus" },
        { id: "7", proUrl: tp7, title: "Portable Crib Baby Portable" },
        { id: "8", proUrl: tp8, title: "Lanolin For Breastfeeding Mothers" },
    ]
}
export const productDetails = {
    productDetailData: [
        {
            id: "1",
            headTitle: "Babies Bloom Foldable Portable Baby Bedding| Newborn Baby Crib",
            rating: "",
            price: "QAR 250,00",
            title: "HYPOALLERGENIC BREATHABLE FABRIC, SUPER SOFT PEARL FIBERFILL, & 3D POLYESTER AS FILLING: Our baby nest sleeper is constructed of these gentle materials. keeps your child warm, and there are no allergic...",
            image: { url: tp1 },
        }
    ]
}
export const babyProducts = {
    title: "Baby Products",
    productData: [
        { id: "2", proUrl: bp1, title: " Baby Wet Wipe Warmer and Dispenser" },
        { id: "3", proUrl: bp2, title: "Frida Soft Sink Baby Bath" },
        { id: "4", proUrl: bp3, title: " Baby Milk Feeding Bottle" },
        { id: "1", proUrl: bp4, title: "Himalaya Babycare Gift Basket" },
        { id: "5", proUrl: bp5, title: "Baby Girl Headband Infant Hair " },
        { id: "6", proUrl: bp6, title: "Imani Breast Pumb i2 Plus" },
        { id: "7", proUrl: bp7, title: "Portable Crib Baby Portable" },
        { id: "8", proUrl: bp8, title: "Lanolin For Breastfeeding Mothers" },
    ]
}
export const babySkinProducts = {
    title: "Baby Skin Products",
    productData: [
        { id: "2", proUrl: bs1, title: "  Baby POST-BIOTIC FACE CREAM - HYDRATION + RADIANCE" },
        { id: "3", proUrl: bs2, title: "Cetaphil Baby Daily Moisturizing" },
        { id: "4", proUrl: bs3, title: " Cetaphil Baby Daily Lotion, Shea" },
        { id: "1", proUrl: bs4, title: "SebaMed Baby Gentle Wash, 200ml" },
        { id: "5", proUrl: bs5, title: "Himalaya Face Body Oil Baby Massage Oil" },
        { id: "6", proUrl: bs6, title: "Himalaya Baby Cream, Face Moisturizer & Day Cream" },
        { id: "7", proUrl: bs7, title: "Tedibar Moisturising Baby Bar 75g*2 with Skin" },
        { id: "8", proUrl: bs8, title: "Little's Organix Nourishing Baby Lotion 400ml " },
    ]
}
export const babyBottlesProducts = {
    title: "Baby Bottles Products",
    productData: [
        { id: "2", proUrl: bbottles1, title: "  Baby POST-BIOTIC FACE CREAM - HYDRATION + RADIANCE" },
        { id: "3", proUrl: bbottles2, title: "Cetaphil Baby Daily Moisturizing" },
        { id: "4", proUrl: bbottles3, title: " Cetaphil Baby Daily Lotion, Shea" },
        { id: "1", proUrl: bbottles4, title: "SebaMed Baby Gentle Wash, 200ml" },
        { id: "5", proUrl: bbottles5, title: "Himalaya Face Body Oil Baby Massage Oil" },
        { id: "6", proUrl: bbottles6, title: "Himalaya Baby Cream, Face Moisturizer & Day Cream" },
        { id: "7", proUrl: bbottles7, title: "Tedibar Moisturising Baby Bar 75g*2 with Skin" },
        { id: "8", proUrl: bbottles8, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "9", proUrl: bbottles9, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "10", proUrl: bbottles10, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "11", proUrl: bbottles11, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "13", proUrl: bbottles13, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "14", proUrl: bbottles14, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "15", proUrl: bbottles15, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "16", proUrl: bbottles16, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "18", proUrl: bbottles18, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "17", proUrl: bbottles17, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "19", proUrl: bbottles19, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "20", proUrl: bbottles20, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "21", proUrl: bbottles21, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "22", proUrl: bbottles22, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "23", proUrl: bbottles23, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "24", proUrl: bbottles24, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "25", proUrl: bbottles25, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "26", proUrl: bbottles26, title: "Little's Organix Nourishing Baby Lotion 400ml " },
        { id: "27", proUrl: bbottles27, title: "Little's Organix Nourishing Baby Lotion 400ml " },

    ]
}