import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetTrendingProductQuery } from "../../products/productSlice";
import TrendingProduct from "./trending-product/TrendingProduct";
import QuiekViewModal from "../../../pages/QueikViewModal/QuiekViewModal";
import { babyProducts } from "../../../common/allProductsData/allProductMockData";


function TrendingProducts() {
  const { data, isLoading, error } = useGetTrendingProductQuery();

  const [modelDataId, setModelDataId] = useState(null)
  const setProduct_id = (id) => {
    setModelDataId(id)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id)
    setShow(true)
  };

  return (
    <>
      <div className="container">
        <div className="row featuredRow">
          <div className="col-lg-12">
            <div className="fishermanHeader d-flex justify-content-center align-items-end mb-4">
              <div className="fisherman-content">
                <h3><strong>Baby Products</strong></h3>
              </div>

            </div>
          </div>
          {babyProducts?.productData?.map((item, i) => {
            return <div className="col-lg-3 col-md-6 col-sm-12" key={item.id}>
              <div className="featuredInfo">
                <div className="featuredFigure">
                  <div className="featuredImg" style={{ textAlign: "center" }}>
                    <Link to={`/product/${item._id}`}>
                      <figure><img src={item?.proUrl} alt="Product" className="imgProduct" /></figure>
                    </Link>
                    <h6><b>{item.title}</b></h6>
                  </div>
                </div>
              </div>
            </div>

          })}
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="fisherman-btn rounded-5">
          <Link to="/products" className="optional-btn">
            View More
          </Link>
        </div>
      </div>

      {modelDataId && (
        <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
          centered />
      )}
    </>
  );
}

export default TrendingProducts;
