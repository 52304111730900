import React, { useState } from "react";
// import Rating from "../../../../shared/rating/Rating";

// import { FiSearch } from "react-icons/fi";
// import { GrAdd } from "react-icons/gr";
// import { BsFillCartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import "../../../home/products/product/ProductItem.css";
import { Link } from "react-router-dom";
import {
  setFeachers, useGetProductDetailQuery,
} from "../../../products/productSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";


import { trendyProducts } from "../../../../common/allProductsData/allProductMockData";

function FeaturedProduct({ isLoading, data, error, handleShow }) {
  const curr = window.localStorage.getItem('currencySym')
  const currencySymbol = curr ? curr : 'ZK'
  const dispacher = useDispatch()

  useEffect(() => {
    dispacher(setFeachers(data))
  }, [data])

  return (
    <>
      {isLoading && (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      )}
      {trendyProducts?.productData?.map((item, i) => {
        return (
          <div className="col-lg-3 col-md-6 col-sm-12" key={item.id}>
            <div className="featuredInfo">
              <div className="featuredFigure">
                <div className="featuredImg" style={{ textAlign: "center" }}>
                  <Link to={`product-details`}>
                    <figure><img src={item?.proUrl} alt="Product" className="imgProduct" /></figure>
                  </Link>
                  <h6><b>{item.title}</b></h6>

                </div>
              </div>


            </div>

          </div>
        );
      })}
      <div className="d-flex justify-content-center">
        <div className="fisherman-btn rounded-5">
          <Link to="/products" className="optional-btn">
            View More
          </Link>
        </div>
      </div>

      {error && (
        <div className="alertMsg mb-4" role="alert">
          No Data Found
        </div>
      )}

    </>
  );
}

export default FeaturedProduct;
